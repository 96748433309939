<template>
  <div class="w-full flex justify-center items-center relative border-b border-b-white/20 flex-col">

      <!--<h3 class="w-full py-8 text-turquoise-500 font-brand font-bold text-3xl text-center">Projects</h3>-->

      <div class="text-center text-3xl font-brand font-bold z-20 text-turquoise-500 sm:text-[5vw] py-24">
          <h2>Projects</h2>
        </div>

      <div class="w-full duration-300 z-10" :class="[expandProjects ? 'max-h-[5000vh] pb-[20vh]' : 'max-h-[70vh]', 'overflow-hidden']">
        <!-- <HomeBoard :posts="posts" /> -->

        <HomeTiles :projects="projectState"></HomeTiles>
      </div>

      <div class="w-full sticky z-20 left-0 bottom-0 pointer-events-none flex justify-center items-center">
        <div class="bg-gradient-to-b from-transparent to-black -mt-40 pt-36 pb-12 w-full flex justify-center items-center">
          <button @click="expandProjects = !expandProjects" class="text-white text-4xl font-brand font-bold pointer-events-auto hover:tracking-widest duration-300 hover:scale-120 hover:underline">{{ expandProjects ? "Minimize" : "View All Project" }}</button>
        </div>
      </div>
    </div>
</template>

<script setup>

// const landingProjectState = useState("landingProject");
const projectState = useState('project')

// const posts = computed(() => {
//   let _project = projectState.value;
//   let _posts = [];

//   let _featuredPosts = [];

//   if (_project?.featured?.projects?.length > 0) {
//     for (let project of _project.featured.projects) {
//       _featuredPosts.push({
//         id: project.project_id,
//         title: project.project_name,
//         image: project.project_banner,
//         logo: project.project_logo,
//         description: project.project_description,
//         ath: project.project_ath,
//         tiers: project.project_tier_allocation.length > 0 ? project.project_tier_allocation[0] : [],
//         type: "project",
//       });
//     }
//   }

//   let _recentPosts = [];

//   if (_project?.recent?.projects?.length > 0) {
//     for (let project of _project.recent.projects) {
//       _recentPosts.push({
//         id: project.project_id,
//         title: project.project_name,
//         image: project.project_banner,
//         logo: project.project_logo,
//         description: project.project_description,
//         ath: project.project_ath,
//         tiers: project.project_tier_allocation.length > 0 ? project.project_tier_allocation[0] : [],
//         type: "project",
//       });
//     }
//   }

//   _posts = [
//     {
//       label: "Recent",
//       key: "recent",
//       posts: _recentPosts,
//     },
//     {
//       label: "Featured",
//       key: "featured",
//       posts: _featuredPosts,
//     },
//   ];

//   return _posts;
// });

const expandProjects = ref(false);


</script>

<style>

</style>